import { twMerge } from "tailwind-merge";
import { BUTTONS } from "~/components/common";
import { Icon, type IconProps } from "~/components/icons/Icon";
import type { SimpleButtonProps } from "./types";

export type SimpleCircleButtonProps = IconProps & Omit<SimpleButtonProps, "label">;

export const SimpleCircleButton = (props: SimpleCircleButtonProps) => (
  <button
    title={props.title}
    type={props.type || "button"}
    onClick={props.onClick}
    class={twMerge(
      BUTTONS.colors,
      BUTTONS.typography,
      BUTTONS.borders,
      BUTTONS.hoverTransition,
      "flex items-center justify-center rounded-3xl text-center w-10 h-10",
      props.class,
    )}
  >
    <span class="dark:text-white">
      <Icon name={props.iconName} size={props.iconSize} />
    </span>
  </button>
);
